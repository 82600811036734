.App {
  /*background-color: #ddd;*/
}

.App-header {
  text-align: center;
  background-color: #333;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  color: white;
  margin: 2rem;
  border-radius: 10px;
}
